let config = {};

const prefix = 'REACT_APP_';

for (const [key, value] of Object.entries(process.env)) {
  if (!key.startsWith(prefix)) {
    continue;
  }
  const confKey = key.replace(prefix, '');
  config[confKey] = value;
}

config = {
  ...config,
  APP_VERSION: '1.13.0',
  APP_TITLE: 'Maverick Signings',
  // refresh cookie is available in paths prefixed with this value
  // must match backend value
  AUTH_PATH_PREFIX: '/auth',
  // socket connection requests are sent to this backend path
  // must match backend value
  SOCKET_PATH: '/be/socket.io/',
  // socket client will connect to this namespace by default
  // must be a valid backend namespace
  SOCKET_NAMESPACE: 'be',
  // api urls
  API_BE_URL: config.API_URL + '/be',
  // delay after which the search query is sent
  DATA_TABLE_SEARCH_DELAY: 1500,
  // number of seconds after which an inactive user is considered idle
  USER_IDLE_DELAY: 180,
  // max number of images uploaded concurrently in chat
  CHAT_MAX_FILES: 3,
  // max accepted size (in bytes) of images uploaded in chat
  CHAT_MAX_FILE_SIZE: 3145728,
  // number of digits required for a valid phone number
  PHONE_NR_OF_DIGITS: 10,
};

export default config;